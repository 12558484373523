import axios from "axios";

class LookupApi {
  static fetchPublicPharmacies(searchTerms) {
    return axios.get(
      `https://synapseapi.rxbridge.com/api/pharmacies/public?searchTerms=${searchTerms}`
    );
  }
}

export default LookupApi;
