import React, { useState } from "react";
import LookupApi from "./lookupApi";
import PharmacyResult from "./PharmacyResult";
import NoResults from "./no_results";
import PublicPharmacyHeader from "./PublicPharmacyHeader";
import MapComponent from "./MapComponent";

export default function PublicPharmacySearch() {
  const [searchTerms, setSearchTerms] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [results, setResults] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerms(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    search(searchTerms);
  };

  const search = () => {
    setIsSubmitting(true);
    setSelectedResult(null);
    setSelectedMarker(null);

    LookupApi.fetchPublicPharmacies(searchTerms)
      .then((res) => {
        setResults(res.data);
        setIsSubmitting(false);
        setHasSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        setHasSubmitted(true);
      });
  };

  const handleResultClick = (r) => {
    setSelectedMarker(null);
    setSelectedResult(r);
  };

  const handleMarkerSelect = (marker, props) => {
    setSelectedResult(null);
    setSelectedMarker({ marker, props });
  };
  const onInfoWindowClose = () => {
    setSelectedMarker(null);
    setSelectedResult(null);
  };

  return (
    <div style={{ width: "100%" }}>
      <PublicPharmacyHeader />
      <div className="pps-wrapper">
        <div className="pps-heading">
          <div className="pps-alert pps-alert-primary" role="alert">
            <p>
              The NARSRx Pharmacy Network includes nearly every major chain and
              independent pharmacy and covers 97% of pharmacies in the United
              States. To find out if your pharmacy is in our network or to find
              the nearest pharmacy, enter your zip code below. Confirm
              directions and store hours with the pharmacy directly.
            </p>
            <p>
              NARSRx BIN: 984000
              <br />
              NARSRx Phone: (844) 4-NARSRX (844-462-7779)
            </p>
          </div>
        </div>

        <div>
          <form onSubmit={handleSubmit}>
            <div
              className="input-group"
              style={{ maxWidth: "800px", margin: "auto" }}
            >
              <input
                className="input-group-field"
                type="text"
                value={searchTerms}
                onChange={handleInputChange}
              />
              <div className="input-group-button">
                <button
                  className="nars-button"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ minWidth: "50px" }}
                >
                  {isSubmitting ? (
                    <i className="fa fa-spinner fa-pulse fa-fw" />
                  ) : (
                    <i className="fas fa-search" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="pps-result-container">
          {hasSubmitted && results.length == 0 && (
            <NoResults text="No Pharmacies Found" />
          )}
        </div>
        <div className="pps-result-plus-map">
          <div>
            {results &&
              results.length > 0 &&
              results.map((r) => (
                <PharmacyResult
                  key={r.pharmacyId}
                  {...r}
                  selectedMarker={selectedMarker}
                  selectedResult={selectedResult}
                  handleResultClick={handleResultClick}
                />
              ))}
            {results && results.length === 10 && (
              <div className="pps-result-limit">
                Can't find what you are looking for? Results are limited to the
                10 most relevant.
              </div>
            )}
          </div>
          <div
            id="pharmacy-search-map"
            className={results.length > 0 ? "pps-map" : ""}
          >
            {results.length > 0 && (
              <MapComponent
                results={results}
                selectedResult={selectedResult}
                handleMarkerSelect={handleMarkerSelect}
                selectedMarker={selectedMarker}
                onInfoWindowClose={onInfoWindowClose}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
