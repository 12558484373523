import React, { useEffect, useState } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

function MapComponent({
  results,
  selectedResult,
  google,
  handleMarkerSelect,
  selectedMarker,
  onInfoWindowClose,
}) {
  const [boundsOfResults, setBoundsOfResult] = useState(
    new google.maps.LatLngBounds()
  );
  const [mapStyles, setMapStyles] = useState({});
  const [containerStyles, setContainerStyles] = useState({});
  const [allMarkers, setAllMarkers] = useState(null);

  useEffect(() => {
    calcPoints();
    buildMarkers();
    getMapStyles();
    getContainerStyles();
  }, [results]);

  useEffect(() => {
    calcPoints();
    buildMarkers();
    getMapStyles();
    getContainerStyles();
  }, []);

  const calcPoints = () => {
    const ps = results
      .filter((f) => f.isExactCoordinates)
      .map((r) => ({
        lat: r.businessLocationLatitude,
        lng: r.businessLocationLongitude,
      }));
    calcBounds(ps);
  };

  const calcBounds = (ps) => {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < ps.length; i++) {
      bounds.extend(ps[i]);
    }
    setBoundsOfResult(bounds);
  };

  const getMapStyles = () => {
    const width = document.getElementById("pharmacy-search-map").clientWidth;
    const height = document.getElementById("pharmacy-search-map").clientHeight;
    const style = { width: `${width}px`, height: `${height}px` };
    setMapStyles(style);
  };

  const getContainerStyles = () => {
    const width = document.getElementById("pharmacy-search-map").clientWidth;
    const height = document.getElementById("pharmacy-search-map").clientHeight;

    const style = {
      width: `${width}px`,
      height: `${height}px`,
      position: "absolute",
    };
    setContainerStyles(style);
  };

  const onMarkerClick = (props, marker, e) => {
    handleMarkerSelect(marker, props);
  };

  const buildMarkers = () => {
    const markers = results
      .filter((f) => f.isExactCoordinates)
      .map((r) => (
        <Marker
          key={r.pharmacyId}
          pharmacyId={r.pharmacyId}
          onClick={onMarkerClick}
          title={r.name}
          name={r.name}
          position={{
            lat: r.businessLocationLatitude,
            lng: r.businessLocationLongitude,
          }}
        />
      ));

    setAllMarkers(markers);
  };

  return (
    <div>
      <Map
        google={google}
        containerStyle={containerStyles}
        style={mapStyles}
        initialCenter={{
          lat: 42.39,
          lng: -72.52,
        }}
        bounds={boundsOfResults}
      >
        {results.length > 0 && allMarkers}
        <InfoWindow
          position={
            selectedResult
              ? selectedResult.center
              : selectedMarker
              ? selectedMarker.marker.position
              : null
          }
          pixelOffset={new google.maps.Size(0, -30)}
          visible={
            selectedMarker != null ||
            (selectedResult && selectedResult.center.lat != null)
          }
          onClose={onInfoWindowClose}
        >
          <div className="pps-info-window">
            <p>
              {selectedResult
                ? selectedResult.name
                : selectedMarker
                ? selectedMarker.props.name
                : ""}
            </p>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDtAPTWR-kKPLWK295OSgjCPZtfvSAoi3o",
})(MapComponent);
