import React from "react";

const formatPhoneNumbers = (value) => {
  let input = value || "";
  input = input.replace(/\D/g, "");

  if (input.length < 10) {
    return "";
  }

  if (input.length == 11) {
    return `${input[0]} (${input.substring(1, 4)}) ${input.substring(
      4,
      7
    )} - ${input.substring(7, 11)}`;
  }

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);

  return `(${input.substring(0, 3)}) ${input.substring(
    3,
    6
  )} - ${input.substring(6, 10)}`;
};

const PharmacyResult = ({
  name,
  npi,
  pharmacyId,
  businessLocationAddress,
  businessLocationCity,
  businessLocationState,
  businessLocationZip,
  businessLocationPhone,
  businessLocationFax,
  handleResultClick,
  businessLocationLatitude,
  businessLocationLongitude,
  selectedMarker,
  selectedResult,
}) => {
  return (
    <div
      className={
        (selectedMarker && selectedMarker.props.pharmacyId == pharmacyId) ||
        (selectedResult && selectedResult.pharmacyId == pharmacyId)
          ? "pps-result hover"
          : "pps-result"
      }
      onClick={() =>
        handleResultClick({
          name,
          pharmacyId,
          center: {
            lat: businessLocationLatitude,
            lng: businessLocationLongitude,
          },
        })
      }
    >
      <div>
        <h4 style={{ margin: 0 }}>{name}</h4>
        <p>{` NPI: ${npi}`}</p>
      </div>
      <div>
        <p>
          {businessLocationAddress} <br />
          {businessLocationCity}, {businessLocationState} {businessLocationZip}
        </p>
      </div>
      <div>
        <p>
          {businessLocationPhone
            ? "P: " + formatPhoneNumbers(businessLocationPhone)
            : ""}
          <br />
          {businessLocationFax
            ? "F: " + formatPhoneNumbers(businessLocationFax)
            : ""}
        </p>
      </div>
    </div>
  );
};

export default PharmacyResult;
