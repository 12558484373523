import React from "react";
import logo from "./narsrx-logo.png"

const PublicPharmacyHeader = () => (
  <header className="pps-header">
    <div className="">
        <img
          src={logo}
          alt="RxBridge Logo"
          className="rxbridge"
        />
    </div>
    
  </header>
);

export default PublicPharmacyHeader;
