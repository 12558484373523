import "./stylesheets/all.min.css";
import "./stylesheets/slick.css";
import "./stylesheets/scss/base.scss";

import PublicPharmacySearch from './PublicPharmacySearch';

function App() {
  return (
    <PublicPharmacySearch />
  );
}

export default App;
